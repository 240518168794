<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-06-07 16:51:08
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-06-12 11:07:41
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SManagerEduMoralStatisticsScrollWrapper\ClassroomQuantification.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 德育统计-班级量化--->
<template>
    <div class="classroom-quantification">
        <layout-secound :title="'班级量化'">
            <template slot="topLeft">
                <div class="text">单位:分</div>
            </template>

            <template slot="topRight">
                <div class="type-select">
                    <el-select
                        v-model="currentType"
                        placeholder="请选择"
                        clearable
                        @change="handleTypeChange"
                    >
                        <el-option
                            v-for="item in gradeList"
                            :key="item.value"
                            :label="item.gradeName"
                            :value="item.gradeId"
                        >
                        </el-option>
                    </el-select>
                </div>
            </template>

            <div class="crb-content">
                <div class="table">
                    <el-table
                        :data="data"
                        style="width: 100%"
                        height="355px"
                        :default-sort="{
                            prop: 'score', 
                            order: 'ascending'
                        }"
                        :header-cell-style="{
                            'text-align': 'center',
                            background: '#F5F5F5',
                            color: '#555',
                        }"
                        :cell-style="{'text-align': 'center'}"
                        ref="scroll_Table"
                        stripe
                        @mouseenter.native="autoScroll(true)"
                        @mouseleave.native="autoScroll(false)"
                    >
                        <el-table-column
                            prop="rank"
                            label="排名"
                            
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gradeName"
                            label="年级"
                            
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column
                            prop="className"
                            label="班级"
                            
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="score"
                            label="量化分"
                            sortable
                            sort-by="rank"
                            
                            show-overflow-tooltip
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            

            
        </layout-secound>
    </div>
</template>

<script>
import layoutSecound from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-secound.vue";
export default {
    name: "ClassroomQuantification",
    components: {
        layoutSecound,
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        classCurrentGrade: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            // currentType: "0",
            gradeList: [],
            tableData: [
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1517 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1519 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1516 弄",
                },
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1517 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1519 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1516 弄",
                },
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1517 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1519 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1516 弄",
                },
            ],
        };
    },
    computed: {
        currentType:{
            get(){
                return this.classCurrentGrade
            },
            set(val){
                this.$emit('update:classCurrentGrade',val)
            }
        }
    },
    created () {
        this.getGradeList();
    },
    mounted() {
        setTimeout(() => {
            this.autoScroll()
        }, 1000);
        
    },
    beforeDestroy() {
        this.autoScroll(true)
    },
    methods: {
        // 监听年级变化
        handleTypeChange(val){
            this.$emit('handleGradeChange',val)
        },
        // 设置自动滚动
        autoScroll(stop) {
            // console.log(stop);
            if(this.data.length <= 0){
                return 
            }
            const table = this.$refs.scroll_Table
            // 拿到表格中承载数据的div元素
            const divData = table.$refs.bodyWrapper
            // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
            if (stop) {
                //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
                window.clearInterval(this.scrolltimer)
            } else {
                this.scrolltimer = window.setInterval(() => {
                    // 元素自增距离顶部1像素
                    divData.scrollTop += 1
                    // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                    if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
                        // 重置table距离顶部距离
                        divData.scrollTop = 0
                        // 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
                        // divData.scrollTop = divData.scrollTop - divData.scrollHeight / 2
                    }
                }, 150) // 滚动速度
            }
        },
        getGradeList() {
            this._fet(
                "/school/schoolMoralScreenData/gradeList",
                {
                    schoolId:this.$store.state.schoolId
                },
            ).then( res => {
                if(res.data.code == "200"){
                    this.gradeList = res.data.data;
                }
            })
        }

    },
};
</script>

<style lang="scss" scoped>
.classroom-quantification {
    width: 100%;
    height: 432px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 6px 6px 0px rgba(179,179,179,0.24);
    border-radius: 10px;

    .text {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-left: 26px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        height: 33px;
    }

    .type-select {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 33px;
        ::v-deep .el-select {
            width: 91px;
            height: 28px;
            .el-input__inner {
                background: #f1f6ff;
            }
        }
    }
    .crb-content {
        flex:1;
        position: relative;

        .table {
            width: 99%;
            box-sizing: border-box;
            padding: 10px;
            position: absolute;
        }
    }
    

    ::v-deep .el-table.el-table--small .el-table__body tr {
        height: 36px !important;
    }
    ::v-deep .el-table--small .el-table__cell {
        padding: 5px 0;
    } 
}
</style>
