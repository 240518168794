<template>
    <div class="data-overview">
        <div class="content">
            <div 
                class="content-item"
                v-for="item in list"
                :key="item.id"
                :style="{background:item.bgcColor}"
            >
                <div class="name">{{item.name}}</div>
                <div class="info">
                    <div class="num">{{item.num}}</div>
                    <div class="unit">{{item.unit}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"DataOverview",
    props: {
        data: {
            type: Object,
            default:() => {} 
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newValue, oldValue) {
                this.handleData();
            }
        }
    },
    data() {
        return {
            list: [
                {
                    id:"1",
                    key:"addNumber",
                    name:"表扬条数",
                    num:"0",
                    unit:'个',
                    bgcColor:"#D1F7FF"
                },
                {
                    id:"2",
                    key:"addPerson",
                    name:"表扬人数",
                    num:"0",
                    unit:'人',
                    bgcColor:"#D1F7FF"
                },
                {
                    id:"3",
                    key:"addBehaviorNumber",
                    name:"表扬行为数",
                    num:"0",
                    unit:'个',
                    bgcColor:"#D1F7FF"
                },
                {
                    id:"4",
                    key:"subNumber",
                    name:"批评条数",
                    num:"0",
                    unit:'个',
                    bgcColor:"#FFF1F1"
                },
                {
                    id:"5",
                    key:"subPerson",
                    name:"批评人数",
                    num:"0",
                    unit:'人',
                    bgcColor:"#FFF1F1"
                },
                {
                    id:"6",
                    key:"subBehaviorNumber",
                    name:"批评行为数",
                    num:"0",
                    unit:'个',
                    bgcColor:"#FFF1F1"
                },
            ]
        }
    },
    methods: {
        handleData() {
            this.list.forEach(ele => {
                ele.num = this.data[ele.key]
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .data-overview {
        width: 100%;

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding: 0 67px;

            .content-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 123px;
                height: 79px;
                border-radius: 10px;
                // margin-left: 60px;
                margin-top: 21px;

                .name {
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }

                .info {
                    display: flex;
                    align-items: baseline;

                    .num {
                        font-weight: bold;
                        font-size: 22px;
                        color: #333333;
                    }

                    .unit {
                        font-size: 16px;
                        color: #333333;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1540px) {
        .data-overview {
            .content {
                padding: 0 30px;
                .content-item {
                    // margin-left: 33px;
                }
            }
        }
    }
</style>