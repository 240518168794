var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classroom-quantification"},[_c('layout-secound',{attrs:{"title":'班级量化'}},[_c('template',{slot:"topLeft"},[_c('div',{staticClass:"text"},[_vm._v("单位:分")])]),_c('template',{slot:"topRight"},[_c('div',{staticClass:"type-select"},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},on:{"change":_vm.handleTypeChange},model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}},_vm._l((_vm.gradeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.gradeName,"value":item.gradeId}})}),1)],1)]),_c('div',{staticClass:"crb-content"},[_c('div',{staticClass:"table"},[_c('el-table',{ref:"scroll_Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"height":"355px","default-sort":{
                        prop: 'score', 
                        order: 'ascending'
                    },"header-cell-style":{
                        'text-align': 'center',
                        background: '#F5F5F5',
                        color: '#555',
                    },"cell-style":{'text-align': 'center'},"stripe":""},nativeOn:{"mouseenter":function($event){return _vm.autoScroll(true)},"mouseleave":function($event){return _vm.autoScroll(false)}}},[_c('el-table-column',{attrs:{"prop":"rank","label":"排名","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"gradeName","label":"年级","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"score","label":"量化分","sortable":"","sort-by":"rank","show-overflow-tooltip":""}})],1)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }