<!-- 德育统计-考核趋势 -->
<template>
    <div class="assessment-trends">
        <layout-first :title="'考核趋势'">
            <div class="tab">
                <div 
                    class="tab-item"
                    v-for="item in tabList"
                    :key="item.id"
                    :class="[item.id == currentTab ? `${item.currentClassName}` : '']"
                    @click="handleTabClick(item)"
                >
                    {{item.name}}
                </div>
            </div>
            
            <div
                class="chart"
                ref="chart"
                id="myChart"
            ></div>
        </layout-first>
    </div>
</template>

<script>
import * as echarts from "echarts"
import layoutFirst from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-first.vue";
import { debounce } from 'common-local';
export default {
    name: "AssessmentTrends",
    components: {
        layoutFirst,
    },
    props: {
        data: {
            type: Array,
            default:() => {} 
        },
    },
    data() {
        return {
            currentTab: '0',
            tabList: [
                {
                    id: '0',
                    name: '批评',
                    currentClassName:'danger'
                },
                {
                    id: '1',
                    name: '表扬',
                    currentClassName:'success'
                }
            ],
            chart:null
        };
    },
    computed: {
        markPointColor() {
            return this.currentTab == '0' ? "#FB7272" : "#48C78E"
        },
        areaStyle() {
            let obj =  {
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                        {
                            offset: 0, 
                            color: this.currentTab == '0' ? "#FB7272" : "#48C78E"
                        },
                        {
                            offset: 0.5,
                            color: this.currentTab == '0' ? "rgba(251, 114, 114, 0.3)" : "rgba(182,229,187,0.3)" ,
                        },
                        {
                            offset: 1, 
                            color: this.currentTab == '0' ? "rgba(251, 114, 114, 0.1)" : "rgba(182,229,187,0.1)" 
                        },
                    ],
                ),
            }
            return obj
        },
        lineStyleColor() {
            return this.currentTab == '0' ? "#FB7272" : "#48C78E"
        },
        itemStyleColor() {
            return this.currentTab == '0' ? "#FB7272" : "#48C78E"
        },
        chartData() {
            let obj = {
                seriesName: "分",
                xAxisData: [],
                yAxisData: [],
            }

            if(this.data.length > 0){
                obj.xAxisData = this.data.map(item => item.month);
                obj.yAxisData = this.data.map(item => {
                    return this.currentTab == '0' ? item.subScore : item.addScore
                })
            }


            return obj
        }
    },
    watch: {
        data: {
            deep: true,
            handler(newValue, oldValue) {
                this.initChart();
            }
        }
    },
    methods: {
        initChart() {
            // 小屏幕宽度
            const limitWidth = 1540;
            // 浏览器宽度
            let windowWidth = document.body.clientWidth;
            let chartDom = this.$refs.chart;
            this.chart = echarts.init(chartDom);
            let option;
            const {seriesName, xAxisData, yAxisData} = this.chartData;
            option = {
                grid: {
                    left: windowWidth > limitWidth ? "10%" : "15%",
                    top: "15%",
                    width: windowWidth > limitWidth ? "80%" : "72%",
                    height: "75%",
                },
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                        let str = "<div><p>" + params.name + "</p></div>";
                        str +=
                            params.marker +
                            params.seriesName +
                            "：" +
                            params.data;
                        return str;
                    },
                    textStyle: {
                        fontSize: 12,
                    },
                },
                xAxis: {
                    type: "category",
                    name: "月",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#595959",
                        fontSize: 12,
                        lineHeight:30, //标题行高
                        verticalAlign:'top',//标题位置
                        padding: [0, 0, 0, 20],
                    },
                    data: xAxisData,
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "#A0AEC0",
                            type: "dashed",
                        },
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: "#595959",
                        fontSize: 12,
                        margin: 20,
                        interval: 0, // 设置为0显示所有标签
                        rotate: 20,
                        padding: [0, -30, 0, 0],
                        // offset: [30, 0], // 设置x轴标签偏移量

                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#E1E7EA",
                            // type: "dashed",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "分",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#595959",
                        fontSize: 12,
                        padding: [0, 40, 0, 0],
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#E1E7EA",
                            // type: "dashed",
                        },
                    },
                    splitNumber: 5,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#595959",
                        fontSize: 12,
                        margin: 15,
                    },
                },
                series: [
                    {
                        name: seriesName,
                        data: yAxisData,
                        type: "line",
                        smooth: false,
                        markPoint: {
                            symbolSize: [0, 8], // 容器大小
                            symbolOffset: [25, 15], //位置偏移
                            data: [{
                                type: 'max',
                                name: '最大值'
                            }],
                            label: {
                                offset: [0, 0],
                                textStyle: {
                                    color: this.markPointColor,
                                    fontSize: 15,
                                },
                            },
                            tooltip:{
                                show:false
                            }
                        },
                        areaStyle: this.areaStyle,
                        lineStyle: {
                            color: this.lineStyleColor,
                            width: 3,
                        },
                        itemStyle: {
                            color: this.itemStyleColor,
                        },
                    },
                ],
            };
            option && this.chart.setOption(option,true);
            // window.addEventListener("resize", function () {
            //     console.log("变了！！！！！！！！！");
            //     this.chart.resize();
            // });
        },
        handleTabClick(item){
            this.currentTab = item.id;
            this.initChart();
        },
       


    },
    mounted() {
        this.initChart();
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.assessment-trends {
    width: 100%;
    height: 338px;
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 6px 6px 0px rgba(179,179,179,0.24);
    border-radius: 10px;
    .tab {
        position: absolute;
        right: 20px;
        top: 45px;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
        .tab-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 28px;
            background: #F1F6FF;
            border-radius: 4px;
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            cursor: pointer;


            &.danger {
                background: #FB7272;
                color: #fff;
            }

            &.success {
                color:#fff;
                background: #48C78E;
            }
        }
    }

    .chart {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1540px) {
    .assessment-trends {
        .chart {
            width: 100%;
            height: 300px;
            box-sizing: border-box;
        }
    }
}
</style>
